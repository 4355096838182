const filter = document.querySelector('.js-filter');
const trigger = document.querySelector('.js-filter-trigger');
const initial = document.querySelector('.js-initial');
const dismiss = document.querySelectorAll('button[data-dismiss]');

if (filter && trigger) {
    window.addEventListener('click', (e) => {
        if (e.target === trigger) {
            filter.classList.toggle('active');
            trigger.setAttribute('aria-expanded', trigger.getAttribute('aria-expanded') === 'true' ? 'false' : 'true');
        }

        if (e.target !== trigger) {
            filter.classList.remove('active');
            trigger.setAttribute('aria-expanded', 'false');
        }
    }, false);
}

if (initial) {
    window.addEventListener('click', (e) => {
        if (e.target === initial) {
            e.preventDefault();
            initial.classList.toggle('is-active');
            initial.setAttribute('aria-expanded', initial.getAttribute('aria-expanded') === 'true' ? 'false' : 'true');
        }

        if (e.target !== initial) {
            initial.classList.remove('is-active');
            initial.setAttribute('aria-expanded', 'false');
        }
    }, false);
}

if (dismiss.length > 0) {
    for (let i = 0; i < dismiss.length; i++) {
        dismiss[i].addEventListener('click', (e) => {
            e.preventDefault();
            let item = e.target;
            let clName = item.dataset.dismiss;
            let parent = item.closest('.' + clName);
            if (parent) { parent.remove(); }
        }, false);
    }
}
